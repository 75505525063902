.App {
  text-align: center;
  background-color: #282c34;
  font-family: 'Courier New',  Courier, 'Lucida Console';
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
}


*{
  background-color: #282c34;
  color:#00FF41;
  box-sizing: border-box;
  margin:0;
}

* {
  scrollbar-width:none;
  scrollbar-color: #00FF41 #282c34;
}

*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: #282c34;
}

*::-webkit-scrolbar-thumb {
  background-color: #00FF41;
  border-radius: 1em;
  border: 3px solid #282c34;
}


.App *{
  white-space: pre-line;
}