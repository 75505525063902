.ProjectDiv{
    height: 100%;
    overflow-x: hidden;

}

.ProjectHeader{
    margin:.5em;
}
.ProjectComponentList{
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding:0;
    width: 100%;

}




