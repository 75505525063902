.ContactDiv{
    display: flex;
    flex-direction: column;
}

.ProfilePic{
  border-radius: 100em;
  width: 100%;
  margin-bottom: .5em;
}

@media (min-aspect-ratio: 4/4) {

}

@media (max-aspect-ratio: 4/4) {


}
  


.IconFlexbox{
    width: 100%;
    margin-top: .2em;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.IconFlexbox *{
    width: 10%;
    height: 100%;
    cursor: pointer;

}


