.ProjectComponent{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-style: solid;
    border-width: .1em;
    border-radius: 1em;
    margin-bottom: 2em;
    margin-top: 0;
    height:100%;
}
.ProjectComponent h1{
    margin-top: 1em;
    width: fit-content;
    font-size: calc(10px + 2vmin);

}
.ProjectFlexBox{
    display: flex;
}


@media (min-aspect-ratio: 5/6) {
    .ProjectComponent{
        margin-left:8em; margin-right:8em;
        width:80%;
    }
    .ProjectFlexBox{
        flex-direction: row;
        justify-content: space-around;
        align-items: center;    
        margin:1em;
    }
    .ProjectDescription{
        width:90%;
        margin-right: 5em;
    }
    .ProjectFigure img{
        width:10%;
    }
    .ProjectDescription ul li{
    font-size: calc(1px + 2vmin);
    margin-bottom: 1em;
    text-align: start;
}
}


@media (max-aspect-ratio: 5/6) {
    .ProjectFlexBox{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin:1em;
    }
    .ProjectDescription ul{
        text-align: start;
        list-style: none;
        padding: 0;
        font-size: calc(1px + 2vmin);
    }
    .ProjectDescription ul li{
        font-size: calc(10px + 2vmin);
        margin-bottom:1em
    }

}



.ProjectDescription h1{
    font-size: calc(10px + 2vmin);
}







/*Used in the picture for the component*/
.ProjectFigure{
    margin-right:1em;
    margin:1em;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.ProjectFigure img{
    width: 100%;
    border-radius: 1em;
    box-shadow: .5em .5em .5em #00000070;
    cursor: pointer;
}
.ProjectFigure figcaption{
    cursor: pointer;
    text-decoration: underline;
}