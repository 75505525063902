
  .Homepage{
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    max-height: 100%;
    font-size: calc(8px + 2vmin);
  }
  

  @media (min-aspect-ratio: 4/4) {
    .Homepage{
      margin-top: 2em;
      flex-direction: row;
    }
    .Homepage .ContactDiv{
      width: 20%;
      margin-right: 2em;
    }
    .Homepage .OpeningTextDiv{
      width: 60%;
    }
  }

  @media (max-aspect-ratio: 4/4) {
    .Homepage{
      flex-direction: column;
      justify-content: space-between;
      align-content: center;
      max-height:90%;
    }
    .Homepage .ContactDiv{
      align-self: center;
      margin:2em;
      width: 60%;

    }
  }