.AboutMePage{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.AboutMeSections{
    margin-top:2em;
    width: 100%;
    height:100%;
    background-color: #00FF41;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

/*Turns the two div containers for the <ul> elements into flexboxes*/
.WhoIAm{
    display: flex;
    flex-direction: column;
    width:50%;
}
.WhatIDo{
    display: flex;
    flex-direction: column;
    width:50%;
}

.AboutMeSections div h2{
    font-size: calc(10px + 2vmin);
}

.AboutMeSections ul li{
    text-align: left;
    list-style: none;
    font-size: calc(10px + 2vmin);
    margin-bottom: 1em;
}





@media (min-aspect-ratio: 4/4) {
    .AboutMePage .ContactDiv{
        max-width:15%;
        margin:1em;
    }
    .AboutMeSections div ul{
        padding:0;
        list-style: none;
        margin-left:3em; margin-right: 3em; margin-top:1em;
    }
    .AboutMeSections ul li{
        line-height: 1.5em;
        font-size: calc(8px + 2vmin);
    }

}


@media (max-aspect-ratio: 4/4) {
    .AboutMePage .ContactDiv{
        width:50%;
        margin:1em;

    }
    .AboutMeSections div ul{
        padding:0;
        list-style: none;
        margin-left:1em; margin-right: 1em; margin-top:1em;
    }
    .AboutMeSections ul li{
        font-size: calc(8px + 1.8vmin);
    }
    .MobileHidden{
        display: none;
    }


}





.Divider{
    border-style: solid;
    border-width: .1em;
    height: 100%;
    width:0%;
}

