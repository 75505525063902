

.OpeningTextDiv{
    display: flex;
    flex-direction: column;
    align-items: start;
    margin:1em;

}
.OpeningTextDiv p{
    text-align: left;
    font-size: calc(10px + 2vmin);
    
}

/* Not needed
@media (min-aspect-ratio: 4/4) {
    .OpeningTextDiv{
        align-content: center;
        width:100%;
    }
  }
  @media (max-aspect-ratio: 4/4) {
    .OpeningTextDiv{
        width:100%;
        align-content: center;
    }
  }
*/
